import { createNamespacedHelpers } from "vuex";
const {
  mapState
} = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...mapState(["isMobile"])
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  mounted() {
    setTimeout(() => {
      if (this.$route.query.r) {
        localStorage.setItem('r', this.$route.query.r);
      }
    }, 500);
  },
  created() {
    console.log("this.web3Wallet", this.web3Wallet);
    this.web3Wallet && this.web3Wallet.initWeb3Modal();
  }
};