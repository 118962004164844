var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isShow ? _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isShow ? _c('div', {
    staticClass: "tip-box",
    class: [{
      'success': _vm.type == 'success',
      'warn': _vm.type == 'warn',
      'err': _vm.type == 'err',
      'default': _vm.type == 'default'
    }, _vm.isMobile && 'wap']
  }, [_c('div', {
    staticClass: "tip-box-content"
  }, [_vm._v(" " + _vm._s(_vm.content) + " ")])]) : _vm._e()]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };