import store from '@store/index';
export default {
  name: "message",
  props: {
    content: {
      //消息内容
      type: String,
      default: ''
    },
    type: {
      //显示类型  success warn err default
      type: String,
      default: 'default' //success warn err default
    },

    timer: {
      //显示时间
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      isShow: false
    };
  },
  computed: {
    isMobile() {
      return store.state.wallet.isMobile;
    }
  },
  methods: {
    show() {
      //组件显示方法
      this.isShow = true;
      setTimeout(this.hide, this.timer);
    },
    hide() {
      //组件隐藏方法
      this.isShow = false;
      this.remove(); //当被挂载在create.js上是，this.remove()即执行comp.remove()，即从body上移除掉挂载的真实dom，并销毁虚拟dom
    }
  }
};