var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-login-main",
    class: _vm.isMobile && 'wap',
    style: {
      '--width': _vm.isMobile ? '100%' : _vm.remTran(1700)
    }
  }, [_c('div', {
    staticClass: "main-box"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang('Sign In')))]), _c('div', {
    staticClass: "sub-tit"
  }, [_vm._v(_vm._s(_vm.$lang('To continue to SatoshiNetwork')))]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "email"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.loginData.email,
      expression: "loginData.email"
    }],
    attrs: {
      "placeholder": _vm.$lang('Your email')
    },
    domProps: {
      "value": _vm.loginData.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.loginData, "email", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "lock"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.loginData.password,
      expression: "loginData.password"
    }],
    attrs: {
      "type": "password",
      "placeholder": _vm.$lang('Your password')
    },
    domProps: {
      "value": _vm.loginData.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.loginData, "password", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item orange f-btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('resetpass');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Forgot your password?')) + " ")]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": _vm.onLogin
    }
  }, [_vm._v(_vm._s(_vm.$lang('Sign In')))])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "or"
  }, [_vm._v(_vm._s(_vm.$lang('or')))])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('register');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Create Account')))])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };