import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      loginData: {
        email: "",
        password: ""
      }
    };
  },
  watch: {
    isSign: {
      async handler(val) {
        if (val == 1) {
          this.onSkip('/');
        }
      },
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  mounted() {},
  created() {},
  methods: {
    ...userVuex.mapActions(['getUserData', 'loginOut']),
    onSkip(path) {
      this.$router.push(path);
    },
    async onLogin() {
      if (this.loginData.email == '') {
        this.$toasted.error(this.$lang('Please enter you email'));
        return false;
      }
      if (this.loginData.password == '') {
        this.$toasted.error(this.$lang('Please enter password'));
        return false;
      }
      let rs = await this.$http.post("/auth/login", this.loginData);
      if (rs.code == 0) {
        localStorage.setItem("token", rs.data.token);
        Cookies.set('token', rs.data.token);
        this.$toasted.success(rs.message);
        this.getUserData();
        return false;
      } else {
        this.$toasted.error(rs.message);
        return false;
      }
    }
  }
};