var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-main",
    class: _vm.isMobile && 'wap',
    style: {
      '--width': _vm.isMobile ? '100%' : _vm.remTran(1700)
    }
  }, [_vm.isSign == 1 ? [_c('div', {
    staticClass: "main-box"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang('WELCOME SATOSHI NETWORK')))]), _c('div', {
    staticClass: "sub-tit"
  }, [_vm._v(_vm._s(_vm.$lang('Satoshi Network is a fully decentralized EVM-compatible Bitcoin L2 that uses BTC as Gas.')))]), _c('div', {
    staticClass: "brief"
  }, [_c('p', [_vm._v("1: " + _vm._s(_vm.$lang('EARN POINT BY COMPLETING DAILY TASKS.')))]), _c('p', [_vm._v("2: " + _vm._s(_vm.$lang('INVITE MORE FRIENDS TO JOIN.')))])]), _c('div', {
    staticClass: "task-list"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang('TASK LIST')))]), _c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/task/discord');
      }
    }
  }, [_c('div', {
    staticClass: "num"
  }, [_vm._v("1.")]), _vm._v(" " + _vm._s(_vm.$lang('Task: Enter discord => Get [?] reward')))]), _c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/task/twitter');
      }
    }
  }, [_c('div', {
    staticClass: "num"
  }, [_vm._v("2.")]), _vm._v(" " + _vm._s(_vm.$lang('Task: follow twitter and bind wallet address => get [?] reward')))]), _c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/task/twittereply');
      }
    }
  }, [_c('div', {
    staticClass: "num"
  }, [_vm._v("3.")]), _vm._v(" " + _vm._s(_vm.$lang('[Daily Task] Reply to other hot tweets 2 => get [?] reward')))])])]), _c('div', {
    staticClass: "center-div"
  }, [_vm._v(_vm._s(_vm.$lang('MY SCORE:')) + " " + _vm._s(_vm.userData.score) + ", " + _vm._s(_vm.$lang('INVITES:')) + " " + _vm._s(_vm.userData.invite_count))])])] : [_c('m-loading')]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };