import { lazyload } from '@assets/js/lazyload';
Promise.resolve().then(() => {
  let imgArr = [].slice.call(document.querySelectorAll('.image'));
  lazyload(imgArr);
});
export default {
  name: 'myImage',
  props: {
    url: {
      type: String,
      default: ''
    },
    radius: {
      type: String,
      default: '20'
    },
    size: {
      type: String,
      required: true,
      default: '100%'
    },
    ratio: {
      type: String,
      default: '100%'
    }
  }
};