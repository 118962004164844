import store from "@store/index";
let mobileFn = store._mutations["wallet/SET_ISMOBILE"][0];
let clientWidthFn = store._mutations["wallet/SET_CLIENTWIDTH"][0];
// console.log(isMobile)
function setRem(isFlag) {
  let _isMobile = false;
  let htmlWidth = null;
  htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;

  //得到html的Dom元素
  let htmlDom = document.getElementsByTagName("html")[0];
  let sUserAgent = navigator.userAgent.toLowerCase();
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
    //wap
    _isMobile = true;
    htmlDom.style.fontSize = htmlWidth / 3 + "px";
  } else {
    _isMobile = false;
    htmlDom.style.fontSize = htmlWidth / 10 + "px";
    if (isFlag) {
      if (!(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) {
        htmlDom.style.fontSize = (htmlWidth - 17) / 10 + "px";
      } else {
        htmlDom.style.fontSize = htmlWidth / 10 + "px";
      }
    } else {
      htmlDom.style.fontSize = htmlWidth / 10 + "px";
    }
    if (htmlWidth <= 1600 && htmlWidth > 1140) {
      htmlDom.style.fontSize = htmlWidth / 8.2 + "px";
    } else if (htmlWidth <= 1140) {
      _isMobile = true;
      htmlDom.style.fontSize = htmlWidth / 3 + "px";
    }
  }
  clientWidthFn(htmlWidth);
  mobileFn(_isMobile);
}

function throttle(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

// 初始化
setRem(true);
// 改变窗口大小时重新设置 rem

window.addEventListener("resize", throttle(setRem, 0));
