import Vue from "vue";
import vailcode from "@utils/errcode";
import lang from "@i18n/i18nOption.json";
const { langDefault, langList } = lang;

import web3Wallet from "@web3Wallet/index";
import web3Config from "@Web3WalletConfig/config";
import BigNumber from "bignumber.js";
export default {
  namespaced: true,
  state: {
    isMobile: false,
    isLang: "En",
    setting: {},

    balance: {}, //代币余额
    connect: false,
    isAddress: false,
    netIdError: true,
    netId: null,
    checkWalletHandle: null,
    address: "",
    btnTop: false,
    isBuyShow:false
  },
  mutations: {
    SETISBUYSHOW: (state, payload) => {
      state.isBuyShow = payload;
    },
    setBtnTop(state, payload) {
      state.btnTop = payload;
    },
    SETLANG: (state, payload) => {
      state.isLang = payload;
    },
    SET_ISMOBILE: (state, payload) => {
      state.isMobile = payload;
    },
    SETSETTING: (state, payload) => {
      state.setting = payload;
    },

    CLEAR_CHECKWALLETHANDLE: (state) => {
      clearInterval(state.checkWalletHandle);
    },
    SET_CHECKWALLETHANDLE: (state, payload) => {
      state.checkWalletHandle = payload;
    },
    CLEARCHECKWALLETHANDLE: (state) => {
      clearInterval(state.checkWalletHandle);
    },
    SET_BALANCE: (state, payload) => {
      // console.log('payload', payload);
      for (const key in payload) {
        Vue.prototype.$set(state.balance, key, payload[key]);
      }
    },
    SET_BNB_BALANCE: (state, payload) => {
      state.bnbBalance = payload;
    },
    SET_CHANGESTATUS: (state, { connect, isAddress, netIdError, netId, address }) => {
      state.connect = connect;
      state.isAddress = isAddress;
      state.netIdError = netIdError;
      state.netId = netId;
      state.address = address;
    },
    SET_CLIENTWIDTH: (state, payload) => {
      state.clientWidth = payload;
    },
  },
  actions: {
    //获取余额
    getBalance: async ({ commit }) => {
      try {
        const { value, formatted } = await web3Wallet.getBalance(web3Wallet.address);
        commit("SET_BALANCE", {
          value: BigNumber(value).toFixed(0),
          label: formatted,
        });
        // console.log(state.balance);
      } catch (err) {}
    },
    getSetting: async ({ state, commit, rootState }, callback) => {
      let lang = "";
      const filterLang = (val) => {
        return langList.filter((item) => item.value.includes(val))[0];
      };
      if (localStorage.getItem("lang")) {
        const langObj = filterLang(localStorage.getItem("lang"));
        lang = langObj.value;
      } else {
        const langObj = filterLang(langDefault);
        lang = langObj.value;
      }

      commit("SETLANG", lang);

      const rs = await Vue.prototype.$http.post("/init", { lang });
      vailcode(rs, () => {
        commit("SETSETTING", rs.data);
        typeof callback == "function" && callback(rs.data);
      });
      return rs.data;
    },

    //连接web3
    doConnect: async ({ commit, dispatch }, bool = false) => {
      return new Promise(async (resolve, reject) => {
        try {
          const status = await web3Wallet.connect();
          console.log("doConnect status:", status);
          commit("SET_CHANGESTATUS", {
            connect: web3Wallet.connected,
            isAddress: typeof web3Wallet.address === "string" ? true : false,
            netIdError: web3Wallet.netIdError,
            netId: web3Wallet.netId,
            address: web3Wallet.address,
          });
          console.log("doConnect", bool);
          bool && dispatch("checkWallet");
          resolve();
        } catch (err) {
          console.log("doConnect Error: ", err);
        }
      });
    },
    checkWallet: async ({ commit, dispatch }) => {
      console.log("checkWallet//////");
      web3Wallet.watchAccountChange(async (account) => {
        await web3Wallet.checkNetId();
        console.log("checkWallet account info:", account);
        await dispatch("getBalance");
        commit("SET_CHANGESTATUS", {
          connect: web3Wallet.connected,
          netIdError: web3Wallet.netIdError,
          netId: web3Wallet.netId,
          isAddress: typeof web3Wallet.address === "string" ? true : false,
          address: web3Wallet.address,
        });
        if (account && !account.address) {
          // console.log("账号为空,断开钱包并退出登录");
          // web3Wallet.disConnect();
          return;
        }
      });
      web3Wallet.watchNetworkChange(async (_) => {
        await dispatch("getBalance");
        commit("SET_CHANGESTATUS", {
          connect: web3Wallet.connected,
          netIdError: web3Wallet.netIdError,
          netId: web3Wallet.netId,
          isAddress: typeof web3Wallet.address === "string" ? true : false,
          address: web3Wallet.address,
        });
      });
    },
  },
  getters: {
    isWallet: (state) => state.connect && state.isAddress && !state.netIdError,
    balanceLabel: (state) => BigNumber(state.balance).div(10 ** web3Config.coin_decimals),
  },
};
