var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-task-main",
    class: _vm.isMobile && 'wap',
    style: {
      '--width': _vm.isMobile ? '100%' : _vm.remTran(1700)
    }
  }, [_c('div', {
    staticClass: "main-box"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step1. Bind Wallet (ETH)')) + " ")]), _c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/task/bindwallet', 1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step1. Bind Wallet')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step2. Follow @SatoshiNetwork1 on Twitter')) + " ")]), _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/twitter-tweet.png`)
    }
  })]), _c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": function ($event) {
        return _vm.onSkip('follow');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step2. Follow @SatoshiNetwork1 on Twitter')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step3. Please click this button and tweet a verification message on Twitter')) + " ")]), _c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": function ($event) {
        return _vm.onSkip('tweet');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step3. Tweet')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step4. Copy & submit the share link of this tweet')) + " ")]), _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/twitter-share.png`)
    }
  })]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.link,
      expression: "link"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$lang('tweet link, start with https://')
    },
    domProps: {
      "value": _vm.link
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.link = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": _vm.onVerify
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step4. Verify & Get Rewards')))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };