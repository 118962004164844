var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-task-main",
    class: _vm.isMobile && 'wap',
    style: {
      '--width': _vm.isMobile ? '100%' : _vm.remTran(1700)
    }
  }, [_c('div', {
    staticClass: "main-box"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step1. Post comments')) + " ")]), _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/twitter-comment.png`)
    }
  })]), _c('div', {
    staticClass: "sub-tit"
  }, [_vm._v(" Reply to the hot [" + _vm._s(this.setting.daliyCall) + "] tweet, and the content must contain keywords ")]), _c('div', {
    staticClass: "brief"
  }, [_vm._v(" " + _vm._s(this.setting.daliyReply) + " ")]), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: _vm.tweetTxt,
      expression: "tweetTxt"
    }],
    staticClass: "btn btn-orange",
    on: {
      "click": _vm.onTweets
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step1.Copy keywords & Reply')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step2. Copy the comment link')) + " ")]), _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/twitter-share.png`)
    }
  })]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.link,
      expression: "link"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$lang('tweet link, start with https://')
    },
    domProps: {
      "value": _vm.link
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.link = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": _vm.onVerify
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Step2. Verify & Get Rewards')))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };