import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      icons: [{
        name: 'x',
        path: '#'
      }, {
        name: 'airplane',
        path: '#'
      }]
    };
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop'])
  },
  mounted() {
    console.log(this.$route.params.id);
  },
  methods: {
    onSkip(path) {
      open(path, '_blank');
    }
  },
  created() {
    this.$nextTick(() => {
      //页面渲染完，在执行
      let wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};