import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      registerData: {
        email: "",
        password: "",
        crm_password: "",
        captcha: "",
        vcode: "",
        invite_code: ""
      },
      captchaInfo: "",
      sendTimeInterval: null,
      sendLeftTime: 0,
      sendTxt: this.$lang('Send')
    };
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop'])
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.r) {
        localStorage.setItem('r', this.$route.query.r);
        this.registerData.invite_code = this.$route.query.r;
      } else if (localStorage.getItem('r')) {
        this.registerData.invite_code = localStorage.getItem('r');
      }
    }, 500);
    this.getCaptcha();
  },
  created() {},
  methods: {
    onSkip(path) {
      this.$router.push(path);
    },
    async sendVcode() {
      if (this.sendLeftTime > 0) {
        this.$toasted.error(this.$lang('Please wait'));
        return false;
      }
      if (this.registerData.email == '') {
        this.$toasted.error(this.$lang('Please enter you email'));
        return false;
      }
      if (this.registerData.captcha == '') {
        this.$toasted.error(this.$lang('Please enter captcha'));
        return false;
      }
      let rs = await this.$http.post("/captcha/send", {
        "email": this.registerData.email,
        "captcha": this.registerData.captcha,
        "captcha_key": this.captchaInfo.captcha_key,
        "scene": "register"
      });
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.sendLeftTime = rs.data.second;
        this.sendTimeLeftAct();
      } else {
        this.$toasted.error(rs.message);
      }
    },
    sendTimeLeftAct() {
      clearTimeout(this.sendTimeInterval);
      if (this.sendLeftTime > 0) {
        this.sendTxt = this.sendLeftTime + "(s)";
        this.sendTimeInterval = setTimeout(() => {
          this.sendLeftTime = this.sendLeftTime - 1;
          this.sendTimeLeftAct();
        }, 1000);
      } else {
        this.sendTxt = this.$lang('Send');
      }
    },
    async onRegister() {
      if (this.registerData.email == '') {
        this.$toasted.error(this.$lang('Please enter you email'));
        return false;
      }
      if (this.registerData.password == '') {
        this.$toasted.error(this.$lang('Please enter password'));
        return false;
      }
      if (this.registerData.password !== this.registerData.crm_password) {
        this.$toasted.error(this.$lang('Please enter password again'));
        return false;
      }
      if (this.registerData.vcode == '') {
        this.$toasted.error(this.$lang('Please enter email verification code'));
        return false;
      }
      let rs = await this.$http.post("/auth/register", this.registerData);
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.$router.push('signin');
        return false;
      } else {
        this.$toasted.error(rs.message);
        return false;
      }
    },
    async getCaptcha() {
      let rs = await this.$http.post("/captcha/image");
      if (rs.code == 0) {
        this.captchaInfo = rs.data;
      }
    }
  }
};