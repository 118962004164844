export default {
  name: "mLoading",
  props: {
    width: {
      type: [Number, String],
      default: "100"
    },
    size: {
      type: [Number, String],
      default: "20"
    }
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  }
};