import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
export default {
  name: 'myLink',
  props: {
    to: {
      type: String,
      default: '/',
      required: true
    },
    target: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...walletVuex.mapState(['isMobile']),
    vProps() {
      return {
        ...this.$props,
        ...this.$attrs
      };
    }
  },
  methods: {
    toContant(to) {
      window.open(to, target = "_target");
    }
  }
};