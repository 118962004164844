import { langDefault, langList } from '@i18n/i18nOption.json';
export default {
  name: 'myLang',
  computed: {
    lang() {
      return langList;
    }
  },
  data() {
    return {
      langLabel: ''
    };
  },
  created() {
    const filterLang = val => {
      return this.lang.filter(item => item.value.includes(val))[0];
    };
    if (localStorage.getItem('lang')) {
      this.langLabel = filterLang(localStorage.getItem('lang')).label;
      console.log(this.langLabel);
    } else {
      let _lang = filterLang(langDefault);
      this.langLabel = _lang.label;
      localStorage.setItem('lang', _lang.value);
    }
  },
  methods: {
    // onCommand(row) {
    //    console.log(row);
    //    this.langLabel = row.label
    //    this.$i18n.locale = row.value
    //    localStorage.setItem('lang', row.value)
    // }
  }
};