import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {};
  },
  watch: {
    isSign: {
      async handler(val) {
        if (val == 2) {
          this.onSkip('/signin');
        }
      },
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  async mounted() {
    if (this.isSign == 2) {
      this.onSkip('/signin');
    }
    this.getSetting();
  },
  created() {},
  methods: {
    ...walletVuex.mapActions(['getSetting']),
    onSkip(path) {
      this.$router.push(path);
    }
  }
};