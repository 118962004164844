import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popPrompt",
  computed: {
    ...walletVuex.mapState(["isMobile", "clientWidth"])
  },
  props: {
    title: {
      type: String,
      default: "Prompt"
    },
    value: {
      type: String,
      default: ""
    },
    isCancel: {
      type: Boolean,
      default: true
    },
    isConfirm: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: Vue.prototype.$lang("Cancel")
    },
    confirmText: {
      type: String,
      default: Vue.prototype.$lang("Confirm")
    }
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      this.$emit("confirm", this.value);
    }
  }
};