import Vue from 'vue'
let n = 0;
export function lazyload(imgArr) { //监听页面滚动事件
   Vue.prototype.$nextTick(() => {
      let seeHeight = document.documentElement.clientHeight; //可见区域高度
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //滚动条距离顶部高度
      let len = imgArr.length
      for (let i = n; i < len; i++) {
         let img = imgArr[i].children[0] || imgArr[i].childNodes[0]
         if (img.offsetTop < seeHeight + scrollTop) {
            if (img.getAttribute("src") == "default.jpg") {
               img.src = img.getAttribute("data-src");
            }
            n = i + 1;
         }
      }
   })
}