import web3Wallet from "@web3Wallet/index";
import Vue from 'vue'
import Cookies from 'js-cookie'
export default {
   namespaced: true,
   state: {
        userData:null,
        isSign:0
   },
   mutations: {
    SET_USERDATA: (state, payload) => {
        state.userData = payload
    },
    SET_ISSIGN: (state, payload) => {
        state.isSign = payload
    },
   },
   actions: {
        getUserData: async ({ commit, dispatch }) => {
            let token = localStorage.getItem('token') || Cookies.get('token')
            if(!token){
                commit('SET_ISSIGN', 2)
                return false;
            }
            let rs = await Vue.prototype.$http.post("user/info",{token});
            if(rs.code == 0){
                commit('SET_USERDATA', rs.data)
                commit('SET_ISSIGN', 1)
            }else{
                commit('SET_USERDATA', null)
                commit('SET_ISSIGN', 2)
            }
        },
        loginOut: async ({ commit, dispatch }) => {
            let token = localStorage.getItem('token') || Cookies.get('token')
            if(!token){
                commit('SET_ISSIGN', 2)
                return false;
            }
            let rs = await Vue.prototype.$http.post("auth/logout",{token});
            if(rs.code == 0){
               localStorage.removeItem('token'),
               Cookies.remove('token')
               commit('SET_USERDATA', null)
               commit('SET_ISSIGN', 2)
            }
        }
   }
}