var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    class: _vm.isMobile && 'wap',
    attrs: {
      "height": "auto"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "myPop myMt",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "myMt-main"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.value = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "tip"
  }, [_vm._v(_vm._s(_vm.$lang('please confirm to bind the above address')))])]), _vm._t("default"), _c('div', {
    staticClass: "btns"
  }, [_vm.isCancel ? _c('div', {
    staticClass: "btn btn-leave",
    on: {
      "click": _vm.cancelFn
    }
  }, [_vm._v(_vm._s(_vm.cancelText))]) : _vm._e(), _vm.isConfirm ? _c('div', {
    staticClass: "btn btn-submit",
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(_vm._s(_vm.confirmText))]) : _vm._e()])], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };