var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "loadingData"
  }, [_c('div', {
    staticClass: "box",
    style: `width:${_vm.width}px`
  }, [_c('div', {
    staticClass: "icon",
    style: `height:${_vm.width}px`
  }, [_c('svg-icon', {
    attrs: {
      "name": "loading"
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };