import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      link: ""
    };
  },
  watch: {
    isSign: {
      async handler(val) {
        if (val == 2) {
          this.onSkip('/signin');
        }
      },
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  async mounted() {
    if (this.isSign == 2) {
      this.onSkip('/signin');
    }
  },
  created() {},
  methods: {
    ...userVuex.mapActions(['getUserData']),
    onSkip(path, type) {
      if (type == 1) {
        window.open(path);
      } else {
        console.log(window);
        switch (path) {
          case "follow":
            window.open('https://twitter.com/intent/follow?screen_name=SatoshiNetwork1');
            break;
          case "tweet":
            let url = "https://twitter.com/intent/post?text=";
            let text = "Join @SatoshiNetwork1 For Airdrop!!! $STN ⛏\n\n";
            text += "A fully decentralized EVM-compatible Bitcoin L2 that uses BTC as Gas. 🌐 \n\n";
            text += "Use my referral link 🚀: " + location.host + "/register?r=" + this.userData.invite_code;
            window.open(url + encodeURIComponent(text));
            break;
          default:
            this.$router.push(path);
            break;
        }
      }
    },
    async onVerify() {
      let token = localStorage.getItem('token') || Cookies.get('token');
      let rs = await this.$http.post("task/tweets", {
        link: this.link,
        token
      });
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.getUserData();
      } else {
        this.$toasted.error(rs.message);
      }
    }
  }
};