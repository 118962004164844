var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.to && _vm.to[0] !== '/' ? _c('a', {
    attrs: {
      "href": _vm.to,
      "target": _vm.target
    },
    on: {
      "click": function ($event) {
        _vm.isMobile && _vm.toContant(_vm.to);
      }
    }
  }, [_vm._t("default")], 2) : _c('router-link', _vm._b({}, 'router-link', _vm.vProps, false), [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };