const vAnchor = {
    inserted(el, binding) {
        let callback = binding.value
        let arg = binding.arg
        el.onclick = function () {
            Promise.resolve().then(() => {
                document.body.scrollTop = document.documentElement.scrollTop = document.getElementById(`anchor-${arg}`).offsetTop + 1
                callback(arg)
            })
        }
    }
}

export default vAnchor