var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-main",
    class: _vm.isMobile && 'wap',
    style: {
      '--width': _vm.isMobile ? '100%' : _vm.remTran(1700)
    }
  }, [_c('div', {
    staticClass: "center-div"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo.png')
    }
  })]), _c('div', {
    staticClass: "tip"
  }, [_vm._v(" We couldn't verify your profile. Come back soon and try again. ")]), _c('div', {
    staticClass: "btns",
    on: {
      "click": function ($event) {
        return _vm.onSkip('https://twitter.com/SatoshiNetwork1');
      }
    }
  }, [_c('div', {
    staticClass: "btn"
  }, [_vm._v("CONTACT US")])])])]), _c('div', {
    staticClass: "copyright"
  }, [_vm._v(" Powered by IOTEX ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };