import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      link: "",
      tweetTxt: ""
    };
  },
  watch: {
    isSign: {
      async handler(val) {
        if (val == 2) {
          this.onSkip('/signin');
        }
      },
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop', 'setting']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  async mounted() {
    if (this.isSign == 2) {
      this.onSkip('/signin');
    }
    this.getSetting(() => {
      this.tweetTxt = this.setting.daliyReply + "\n\n";
      this.tweetTxt += "Use my referral link 🚀: " + location.host + "/register?r=" + this.userData.invite_code;
    });
  },
  created() {},
  methods: {
    ...userVuex.mapActions(['getUserData']),
    ...walletVuex.mapActions(['getSetting']),
    onSkip(path) {
      this.$router.push(path);
    },
    onTweets() {
      // let url = "https://twitter.com/intent/post?text=";
      // let text = "Join @SatoshiNetwork1 For Airdrop!!!⛏\n\n";
      //  text += "A fully decentralized EVM-compatible Bitcoin L2 that uses BTC as Gas. 🌐 \n\n"
      // text += "Use my referral link 🚀: "+location.host+"/register?r="+this.userData.invite_code;
      // window.open(url + encodeURIComponent(text))
      setTimeout(() => {
        window.open(this.setting.daliytweets);
      }, 500);
    },
    async onVerify() {
      let token = localStorage.getItem('token') || Cookies.get('token');
      let rs = await this.$http.post("task/tweets/daily", {
        link: this.link,
        token
      });
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.getUserData();
      } else {
        this.$toasted.error(rs.message);
      }
    }
  }
};