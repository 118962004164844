import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
export default {
  name: 'webLayout',
  // beforeDestroy() {
  //   this.clearCheckWalletHandle()
  // },
  // destroyed() {
  //   this.clearCheckWalletHandle()
  // },
  created() {
    // this.doConnect()
  },
  watch: {},
  computed: {},
  methods: {}
};