
export const headMixin = {
   data() {
      return {
         icons: [
            {
               name: 'discord',
               path: 'https://discord.gg/sakurameta'
            },
            {
               name: 'twitter',
               path: 'https://twitter.com/SakuraMeta'
            }
         ],
      }
   },

   methods: {

      onSkip(path) {
         open(path, '_blank')
      },
   },
}