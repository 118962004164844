var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "image",
    style: {
      borderRadius: _vm.radius.includes('%') ? _vm.radius : _vm.remTran(_vm.radius),
      width: _vm.size.includes('%') ? _vm.size : _vm.remTran(_vm.size),
      '--ratio': _vm.ratio
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.url,
      "alt": "",
      "data-url": ""
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };