var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "head",
    staticClass: "head",
    class: [_vm.isMobile && 'wap']
  }, [_c('div', {
    staticClass: "con"
  }, [_c('div', {
    staticClass: "logo",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo.png')
    }
  })]), _c('div', {
    staticClass: "btns"
  }, [_vm.isSign == 1 ? [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSignOut();
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Home')))]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSignOut();
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Sign out')))])] : [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/signin');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Sign in')))]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/register');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Register')))]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/resetpass');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Reset password')))])]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };