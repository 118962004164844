import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      isShowBindWallet: false
    };
  },
  watch: {
    isSign: {
      async handler(val) {
        if (val == 2) {
          this.onSkip('/signin');
        }
      },
      immediate: true
    },
    connect: {
      async handler(val) {},
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop', 'connect', 'address']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  async mounted() {
    if (this.isSign == 2) {
      this.onSkip('/signin');
      return false;
    }
  },
  async created() {
    const walletConnected = localStorage.getItem("WALLET_CONNECTED");
    if (walletConnected == 'true' || walletConnected == true) {
      await this.doConnect(true);
    }
  },
  methods: {
    ...walletVuex.mapActions(['doConnect']),
    ...userVuex.mapActions(['getUserData']),
    onSkip(path) {
      this.$router.push(path);
    },
    async onConfirm(address) {
      let token = localStorage.getItem('token') || Cookies.get('token');
      let rs = await this.$http.post("task/wallet", {
        address,
        token
      });
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.getUserData();
      } else {
        this.$toasted.error(rs.message);
      }
      this.isShowBindWallet = false;
    },
    onCancel() {
      this.isShowBindWallet = false;
    }
  }
};