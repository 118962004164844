const stop = body => body.style.overflow = 'hidden';
const move = body => body.style.overflow = ''; //出现滚动条

export default {
  name: 'popDialog',
  props: {
    show: Boolean,
    closeModel: {
      default: true,
      type: Boolean
    },
    //是否可以通过点击 modal 关闭 Dialog
    modal: {
      default: true,
      type: Boolean
    }
  },
  watch: {
    // show(bool) {
    //    this.$nextTick(() => {
    //       const body = document.querySelector("body");
    //       if (bool) {
    //          stop(body)
    //       } else {
    //          move(body)
    //       }
    //    })
    // }
  }
};