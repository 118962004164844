import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
export default {
  name: "popPrompt",
  computed: {
    ...walletVuex.mapState(["isMobile", "clientWidth", 'address']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  props: {
    isCancel: {
      type: Boolean,
      default: true
    },
    isConfirm: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: Vue.prototype.$lang("Cancel")
    },
    confirmText: {
      type: String,
      default: Vue.prototype.$lang("Bind Wallet")
    }
  },
  data() {
    return {
      value: ''
    };
  },
  watch: {
    '$attrs.show': {
      handler(bool) {
        if (bool) {
          this.value = this.userData.wallet_address || this.address;
          if (this.userData.wallet_address) {
            this.confirmText = Vue.prototype.$lang("Unbind Wallet");
          } else {
            this.confirmText = Vue.prototype.$lang("Bind Wallet");
          }
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      if (this.userData.wallet_address) {
        this.value = "";
      }
      this.$emit("confirm", this.value);
    }
  }
};