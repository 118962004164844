var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-login-main",
    class: _vm.isMobile && 'wap',
    style: {
      '--width': _vm.isMobile ? '100%' : _vm.remTran(1700)
    }
  }, [_c('div', {
    staticClass: "main-box"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang('Reset your password')))]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "email"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.resetData.email,
      expression: "resetData.email"
    }],
    attrs: {
      "placeholder": _vm.$lang('Your email')
    },
    domProps: {
      "value": _vm.resetData.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.resetData, "email", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "lock"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.resetData.password,
      expression: "resetData.password"
    }],
    attrs: {
      "type": "password",
      "placeholder": _vm.$lang('New password')
    },
    domProps: {
      "value": _vm.resetData.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.resetData, "password", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "lock"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.resetData.crm_password,
      expression: "resetData.crm_password"
    }],
    attrs: {
      "type": "password",
      "placeholder": _vm.$lang('New password again')
    },
    domProps: {
      "value": _vm.resetData.crm_password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.resetData, "crm_password", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input captcha-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "captcha"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.resetData.captcha,
      expression: "resetData.captcha"
    }],
    attrs: {
      "placeholder": _vm.$lang('Input captcha')
    },
    domProps: {
      "value": _vm.resetData.captcha
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.resetData, "captcha", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "captcha"
  }, [_c('img', {
    attrs: {
      "src": _vm.captchaInfo.src
    },
    on: {
      "click": _vm.getCaptcha
    }
  })])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input send-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "vcode"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.resetData.vcode,
      expression: "resetData.vcode"
    }],
    attrs: {
      "placeholder": _vm.$lang('Input your email verification code')
    },
    domProps: {
      "value": _vm.resetData.vcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.resetData, "vcode", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": _vm.sendVcode
    }
  }, [_vm.sendLeftTime > 0 ? [_c('svg-icon', {
    attrs: {
      "name": "cycle"
    }
  })] : [_c('svg-icon', {
    attrs: {
      "name": "send"
    }
  })], _vm._v(" " + _vm._s(_vm.sendTxt) + " ")], 2)])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": _vm.onLogin
    }
  }, [_vm._v(_vm._s(_vm.$lang('Submit')))])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "or"
  }, [_vm._v(_vm._s(_vm.$lang('or')))])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/signin');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('sign in to an existing account')))])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };