var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [_vm.isMobile && 'wap'],
    attrs: {
      "id": "app"
    }
  }, [_c('my-header'), _c('web-layout'), _c('my-footer')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };