import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      username: ""
    };
  },
  watch: {
    isSign: {
      async handler(val) {
        if (val == 2) {
          this.onSkip('/signin');
        }
      },
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  async mounted() {
    if (this.isSign == 2) {
      this.onSkip('/signin');
    }
  },
  created() {},
  methods: {
    ...userVuex.mapActions(['getUserData']),
    onSkip(path) {
      this.$router.push(path);
    },
    onDiscord() {
      window.open('https://discord.com/invite/satoshinetwork');
    },
    async onVerify() {
      let token = localStorage.getItem('token') || Cookies.get('token');
      let rs = await this.$http.post("task/discord", {
        username: this.username,
        token
      });
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.getUserData();
      } else {
        this.$toasted.error(rs.message);
      }
    }
  }
};