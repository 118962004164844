import { headMixin } from '@components/myHeader/headMixin';
import { createNamespacedHelpers } from "vuex";
const {
  mapState
} = createNamespacedHelpers('wallet');
export default {
  name: 'myFooter',
  mixins: [headMixin],
  computed: {
    ...mapState(['isMobile'])
  },
  data() {
    return {
      icons: [{
        name: 'x',
        path: '#'
      }, {
        name: 'airplane',
        path: '#'
      }]
    };
  },
  methods: {
    onSkip(path) {
      open(path, '_blank');
    }
  }
};