import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
import loadConfig from "@Web3WalletConfig/config";
import Cookies from 'js-cookie';
export default {
  name: 'index',
  data() {
    return {
      resetData: {
        email: "",
        password: "",
        crm_password: "",
        captcha: "",
        vcode: ""
      },
      captchaInfo: "",
      sendTimeInterval: null,
      sendLeftTime: 0,
      sendTxt: this.$lang('Send')
    };
  },
  computed: {
    ...walletVuex.mapState(['isMobile', 'btnTop'])
  },
  mounted() {
    this.getCaptcha();
  },
  created() {},
  methods: {
    onSkip(path) {
      this.$router.push(path);
    },
    async sendVcode() {
      if (this.sendLeftTime > 0) {
        this.$toasted.error(this.$lang('Please wait'));
        return false;
      }
      if (this.resetData.email == '') {
        this.$toasted.error(this.$lang('Please enter you email'));
        return false;
      }
      if (this.resetData.captcha == '') {
        this.$toasted.error(this.$lang('Please enter captcha'));
        return false;
      }
      let rs = await this.$http.post("/captcha/send", {
        "email": this.resetData.email,
        "captcha": this.resetData.captcha,
        "captcha_key": this.captchaInfo.captcha_key,
        "scene": "reset"
      });
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.sendLeftTime = rs.data.second;
        this.sendTimeLeftAct();
      } else {
        this.$toasted.error(rs.message);
      }
    },
    sendTimeLeftAct() {
      clearTimeout(this.sendTimeInterval);
      if (this.sendLeftTime > 0) {
        this.sendTxt = this.sendLeftTime + "(s)";
        this.sendTimeInterval = setTimeout(() => {
          this.sendLeftTime = this.sendLeftTime - 1;
          this.sendTimeLeftAct();
        }, 1000);
      } else {
        this.sendTxt = this.$lang('Send');
      }
    },
    async onLogin() {
      if (this.resetData.email == '') {
        this.$toasted.error(this.$lang('Please enter you email'));
        return false;
      }
      if (this.resetData.password == '') {
        this.$toasted.error(this.$lang('Please enter password'));
        return false;
      }
      if (this.resetData.password !== this.resetData.crm_password) {
        this.$toasted.error(this.$lang('Please enter password again'));
        return false;
      }
      if (this.resetData.vcode == '') {
        this.$toasted.error(this.$lang('Please enter email verification code'));
        return false;
      }
      let rs = await this.$http.post("/auth/reset", this.resetData);
      if (rs.code == 0) {
        this.$toasted.success(rs.message);
        this.$router.push('signin');
        return false;
      } else {
        this.$toasted.error(rs.message);
        return false;
      }
    },
    async getCaptcha() {
      let rs = await this.$http.post("/captcha/image");
      if (rs.code == 0) {
        this.captchaInfo = rs.data;
      }
    }
  }
};