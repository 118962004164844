// const stop = (body) => body.style.overflow = 'hidden';
// const move = (body) => body.style.overflow = '';//出现滚动条
export default {
  data() {
    return {
      isShow: false
    };
  },
  // watch: {
  //   isShow(bool) {
  //     this.$nextTick(() => {
  //       const body = document.querySelector("body");
  //       if (bool) {
  //         stop(body)
  //       } else {
  //         move(body)
  //       }
  //     })
  //   }
  // },
  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector("body");
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }
    });
  }
};