var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-task-main",
    class: _vm.isMobile && 'wap',
    style: {
      '--width': _vm.isMobile ? '100%' : _vm.remTran(1700)
    }
  }, [_vm.isSign == 1 ? [_c('div', {
    staticClass: "main-box"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang('Bind wallet (ETH)')))]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "email"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.email,
      expression: "userData.email"
    }],
    attrs: {
      "readonly": ""
    },
    domProps: {
      "value": _vm.userData.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userData, "email", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "wallet"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.wallet_address,
      expression: "userData.wallet_address"
    }],
    attrs: {
      "readonly": ""
    },
    domProps: {
      "value": _vm.userData.wallet_address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userData, "wallet_address", $event.target.value);
      }
    }
  })])]), !_vm.userData.wallet_address ? [_vm.address ? [_c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": function ($event) {
        _vm.isShowBindWallet = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Bind Wallet')))])] : [_c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": _vm.doConnect
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Connect Wallet')))])]] : [_c('div', {
    staticClass: "btn btn-orange",
    on: {
      "click": function ($event) {
        _vm.isShowBindWallet = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Unbind Wallet')))])]], 2)])] : [_c('m-loading')], _c('pop-bind-wallet', {
    attrs: {
      "show": _vm.isShowBindWallet
    },
    on: {
      "update:show": function ($event) {
        _vm.isShowBindWallet = $event;
      },
      "confirm": _vm.onConfirm,
      "cancel": _vm.onCancel
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };