import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const userVuex = createNamespacedHelpers('user');
import Cookies from 'js-cookie';
export default {
  name: 'headIndex',
  data() {
    return {};
  },
  watch: {
    isSign: {
      async handler(val) {
        if (val == 2) {
          this.onSkip('/signin');
        }
      },
      immediate: true
    },
    userData: {
      async handler(val) {},
      immediate: true
    }
  },
  computed: {
    route() {
      return this.$route.path;
    },
    ...walletVuex.mapState(['isMobile', 'btnTop']),
    ...userVuex.mapState(['userData', 'isSign'])
  },
  methods: {
    ...userVuex.mapMutations({
      setUserData: 'SET_USERDATA',
      setisSign: 'SET_ISSIGN'
    }),
    ...userVuex.mapActions(['getUserData', 'loginOut']),
    onSkip(path) {
      this.$router.push(path);
    },
    async onSignOut() {
      this.loginOut();
    }
  },
  mounted() {
    this.getUserData();
  }
};